import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import API from '../../services/api.jsx'


function Dropzone(props) {
  const [files, setFiles] = useState([]);
  const [acceptableType, setAcceptableType] = useState('')
  const fileTypeProps = props.fileType

  const {getRootProps, getInputProps} = useDropzone({
    accept: acceptableType,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        ...acceptedFiles,
        preview: URL.createObjectURL(file)
      })));
    API.postAsset(acceptedFiles[0]).then(previewFile => {
        // add temp key to file. Means you can filter temporary images for preview before updating entity
        previewFile.file.temp = true
        props.showImage(acceptedFiles[0], previewFile.file)
    })
    }
  });

  useEffect(() => {
      setAcceptableType('image/*')
  },[fileTypeProps])


  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    if(files.length) {
      files.forEach(file => {
        URL.revokeObjectURL(file.preview)
      })
    }
  }, [files]);

  return (
        <>
          <div key={props.key}{...getRootProps({className: 'dropzone image-wrap'})}>
              <input {...getInputProps()} disabled={props.disabled}/>
              <div className="image-icon-wrap icon-downloading">
                  <p>Upload image</p>
              </div>
          </div>
        </>
  );
}

export default Dropzone