import React from 'react'
import API from '../../../services/api.jsx'
import Loader from '../../layout/loader.jsx'
import Head from '../../layout/head.jsx'
import Content from '../../layout/content.jsx'
import Table from '../../layout/table.jsx'
import moment from 'moment'
import Modal from './modal.jsx'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'

class PortfolioEdit extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			clients: [],
			loading: true,
            popupVisible: false,
			filters: {
				name: ''
			},
            breadcrumb: {
                title: 'Back to templates', path: '/portfolios/templates'
            },
            entity: {
                name: null,
                projects: []
            },
			projects: {
				columns: [{
					label: 'Name',
					key: 'name',
                    order: true,
                }, {
                    label: 'Tags',
                    key: 'tags',
                    view: function(tags) {
                        let tag = tags.map(tag => <div key={tag.id} className="tag">{tag.name}</div>)
                        return <div className="tags">
                            {tag}
                        </div>
                    }
				}, {
					label: 'Updated',
					key: 'updatedAt',
					order: true,
					view: function(field) {
						return moment(field).format('YYYY-MM-DD')
					}
				}],
				entities: [],
                filters: [
                    {
                        label: 'Project name',
                        onChange: debounce(value => {
                            if(value !== this.state.filters.name)
                                this.setState({ filters: { ...this.state.filters, name: value ? '/' + value + '/i' : '' }}, () => {
                                    this.loadEntities()
                                })
                        }, 500)
                    }, {
                        label: 'Search for a tag',
                        type: 'select',
                        value: null,
                        entities: [],
                        onChange: value => {
                            if (value !== this.state.filters.tags) {
                                if (value.id === '') {
                                    const newFilters = this.state.filters
                                    delete newFilters.tags
                                    this.setState({ filters: newFilters, projects: { ...this.state.projects, filters: [this.state.projects.filters[0], { ...this.state.projects.filters[1], value: value }] }}, () => {
                                        this.loadEntities()
                                    })
                                } else {
                                    this.setState({ filters: { ...this.state.filters, tags: value ? value.id : '' }, projects: { ...this.state.projects, filters: [this.state.projects.filters[0], { ...this.state.projects.filters[1], value: value }] }}, () => {
                                        this.loadEntities()
                                    })
                                }
                            }
                        }
                    }
                ],
				actions: [
                    {
                        type: 'selectCheckboxes',
                        onClick: () => {
                            this.setState({...this.state, projects:{...this.state.projects, selectedItems: this.state.projects.entities.map(p => p.id)}})
                        }

                    },
                    {
                        type: 'deselectCheckboxes',
                        onClick: () => {
                            this.setState({...this.state, projects:{...this.state.projects, selectedItems: []}})
                        }

                    },
                    {
                        type: 'dropDown',
                        classList: 'yellow dropdown-btn',
                        label: 'Export',
                        menuProps: {
                            items: [
                                {
                                    label: 'Deck',
                                    key: 'deck'
                                },
                                {
                                    label: 'Portfolio',
                                    key: 'portfolio'
                                }
                            ],
                            onClick: (e) => {
                                const entitiesForPdf = [...this.state.projects.selectedItems]

                                let selectOrNot = entitiesForPdf.length > 0 ? true : false

                                if (selectOrNot) {
                                    
                                    if (e.key === 'deck') {
                                        API.createPortfolio(entitiesForPdf, 'deck').then(res => {
                                            window.open(res)
                                        })
                                    } else if (e.key === 'portfolio') {
                                        API.createPortfolio(entitiesForPdf, 'portfolio').then(res => {
                                            window.open(res)
                                        })
                                    }
                                    
                                } else {
                                    toast.error('You didn\'t select any projects')
                                    this.setState({ loading: false })
                                }   
                            }
                        }
				    },
                    {
                        classList: 'yellow',
                        label: 'Save',
                        onClick: () => {
                            this.save()
                        }
				    }
                ],
				selectedItems: [],
				multiSelect: true,
                isDraggable: true,
                onDragEnd: (param) => {
                    const entities = [...this.state.projects.entities]
                    if(param.destination) {
                        const originalIndex = param.source.index
                        const newIndex = param.destination.index
                        entities.splice(newIndex, 0, entities.splice(originalIndex, 1)[0])

                        const projects = this.state.projects
                        projects.entities = entities
                        this.setState({...this.state, projects: projects})
                    }

                },
                onSelect: (key) => {
                    const selectedItems = [...this.state.projects.selectedItems]
                    const findIndex = selectedItems.indexOf(key)
                    if (findIndex > -1) {
                        selectedItems.splice(findIndex, 1);
                    } else {
                        selectedItems.push(key)
                    }

                    this.setState({...this.state, projects:{...this.state.projects, selectedItems}})
                }
			}
		}
	}

	componentDidMount() {
        API.getTags().then(tags => {
            // create index
            tags = [{ name: 'All tags', id: '' }].concat(tags)
            this.setState({ tags: tags, projects: { ...this.state.projects, filters: [this.state.projects.filters[0], { ...this.state.projects.filters[1], entities: tags, value: tags[0] }]} }, () => {
                this.loadEntities()
            })
        })
	}

	loadEntities() {
        API.read('portfolios', this.props.match.params.portfolioId).then(portfolio => {
            API.getProjectsDetails(this.state.filters).then(entities => {
                const projectsSelected = portfolio.projects.filter(p => p)
                const projectsOrder = portfolio.projectsOrder.filter(p => p)
                const entitiesOrdered = []
                const selectedItems = Array.from(new Set([...this.state.projects.selectedItems, ...projectsSelected]))


                // put first projects in portfolio at beginning
                for(const i in projectsSelected) {
                    const entityIndex = entities.findIndex(e => e && projectsSelected[i] === e.id)
                    if(entityIndex >= 0) {
                        entitiesOrdered.push(entities[entityIndex])
                        // remove these projects from entities
                        entities.splice(entityIndex, 1)
                    }

                    const orderIndex = entities.findIndex(e => e && projectsSelected[i] === e.id)
                    if(orderIndex >= 0) {
                        // remove these projects from projects order array
                        projectsOrder.splice(orderIndex, 1)
                    }
                }

                // add others in projects order to array
                for(const i in projectsOrder) {
                    const entityIndex = entities.findIndex(e => e && projectsOrder[i] === e.id)
                    if(entityIndex >= 0) {
                        entitiesOrdered.push(entities[entityIndex])
                        // remove these projects from entities
                        entities.splice(entityIndex, 1)
                    }
                }

                // add other projects to array
                for(const i in entities) {
                    entitiesOrdered.push(entities[i])
                }

                this.setState({ ...this.state, entity:{ name: portfolio.name }, projects: {...this.state.projects, entities: entitiesOrdered, selectedItems}, loading: false })
            })
        }).catch(err => {
            toast.error('An unexpected error has occured, please try again')
        })
	}

    deleteEntity = () => {
        var r = window.confirm("Are you sure you want to delete this portfolio?");
		if (r === false) return
		this.setState({ loading: true })
		API.delete('portfolios', this.props.match.params.portfolioId).then(() => {
			toast.success('Portfolio has been deleted')
			this.props.history.push('/portfolios/templates')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
    }

    save = () => {
        if(!this.state.entity.name) {
            toast.error('Please enter a name')
        }
        const selectedItems = this.state.projects.selectedItems
        const orderedProjects = []

        // get ordered projects
        for(const i in this.state.projects.entities) {
            const entity = this.state.projects.entities[i]

            orderedProjects.push(entity.id)
        }

        const object = {
            name: this.state.entity.name,
            projects: selectedItems,
            projectsOrder: orderedProjects
        }

        API.update('portfolios', this.props.match.params.portfolioId,object).then(portfolio => {
            this.props.history.push('/portfolios/templates')
            toast.success('Portfolio successfully created')
        }).catch(err => {
            toast.success('There was a problem creating this portfolio')
        })
    }

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={this.state.entity.name} edit={() => this.setState({...this.state, popupVisible: true})} breadcrumb={this.state.breadcrumb}/>
				<Content>
					<Table settings={this.state.projects} />
                    <Modal
						create
                        entity={this.state.entity}
                        popupVisible={this.state.popupVisible}
                        closePopup={() => this.setState({...this.state, popupVisible: false})}
                        updateName={(e) => this.setState({...this.state, entity: {...this.state.entity, name: e.target.value}})}
						save={this.save}
                    />
				</Content>
                <div className="actions under-content">
					<button onClick={() => this.deleteEntity()} className="red">Delete template</button>
				</div>
			</>
	}
}

export default PortfolioEdit;
