import React from 'react'
import Rodal from 'rodal'
import Select from 'react-select'
import PreviewImage from './previewImage'
import Dropzone from './dropzone'
import API from '../../services/api'
import { toast } from 'react-toastify'
import moment from 'moment'

class Form extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
            ...props.entity,
            newFiles: [],
			tags: props.entity.tags ? props.entity.tags : [],
			client: props.entity.client || '',
			name: props.entity.name || '',
        }
	}

	componentDidMount() {
		API.getTags().then(tags => {
			var options = []
			// options.push({key: '', label: '---Choose---'})
			tags.forEach(tag => {
				let option = {}
				option.key = tag.id
				option.label = tag.name

				options.push(option)
			})

			this.setState({
				tagOptions: options
			})
		})
	}

	updateField(e, key) {
		this.setState({ [key]: e.target.value })
	}

	updateBoolean(value, key) {
		this.setState({ [key]: value })
	}

	updateArray(e, key, index) {
		if (key === 'tags') {
			let found = false
			this.state.tags.forEach(tag => {
				if(tag === e.target.value)
					found = true
			})
			if (found)
				return toast.error('Tag already added')
		}

		let tempArray = this.state[key]
		tempArray[index] = e.target.value
		this.setState({ [key]: tempArray })
	}

	onCancel(e, callback) {
		e.preventDefault()
		callback()
	}

	onSubmit(e, callback) {
		e.preventDefault()
		callback()
	}

    showImage = (file, previewFile) => {
        // show image preview and store it's actual file in newFiles
        this.setState({...this.state, slides: [...this.state.slides, previewFile], newFiles: [...this.state.newFiles, file]})
    }

    removeImage = (index) => {
        const slides = this.state.slides
        const slide = slides[index]
        let newFiles = this.state.newFiles
        // remove potential new images
        if(slide.temp) {
            const slideName = slides[index].name
            newFiles = newFiles.filter(f => f.name !== slideName)
        }
        // remove any images being previewed currently
        slides.splice(index, 1)
        this.setState({...this.state, slides ,newFiles})
    }

	newTag = () => {
		this.setState({...this.state, tags: [...this.state.tags, '']})
	}

	removeTag = (index) => {
		const tags = this.state.tags
		tags.splice(index, 1)
		this.setState({...this.state, tags})
	}

	render() {
		return (
            <>
			<form className="projects" onSubmit={(e) => this.onSubmit(e, () => this.props.save(this.state))}>
				{/* <div className="actions clearfix">
					<div className="buttons clearfix">
						<div className="button grey" onClick={(e) => this.onCancel(e, () => this.props.cancel())}>Cancel</div>
						<button className="blue">Save</button>
					</div>
				</div> */}

				<div className="block">
					<div className='header-window'>
						<h2>Create project</h2>
						<button>Save</button>
					</div>

					<div className='scrollable'>
						<div className='section'>
							<span>Project information</span>

							<div className='wrap-inputs col-3'>
								<div>
									<label>Client name</label>
									<input
										value={this.state.client}
										onChange={(e) => this.updateField(e, 'client')}
									/>
								</div>

								<div>
									<label>Project name</label>
									<input
										value={this.state.name}
										onChange={(e) => this.updateField(e, 'name')}
									/>
								</div>

								<div>
									<label>Year{moment().year()}</label>
									<Select
										className='tag-wrap'
										classNamePrefix='tag-select'
										isSearchable={false}
										value={this.state.year}
										onChange={(e) => this.setState({ year: e })}
										options={Array.from(Array(25)).map((_, i) => ({  
											value: moment().year() - i, label: moment().year() - i,
										}))}
									/>
								</div>

								<div>
									<label>Tags</label>
									<Select
										isMulti
										isSearchable={false}
										className='tag-wrap'
										classNamePrefix='tag-select'
										value={this.state.tags}
										onChange={(e) => this.setState({ tags: e })}
										options={this.state.tagOptions?.map(option => ({
											label: option.label,
											value: option.key
										}))}
										// getOptionLabel={entity => entity.name}
										// getOptionValue={entity => entity.id}
									/>
									{/* {console.log(this.state.tags, this.state.tagOptions)} */}
									{/* {this.state.tags?.map((tag, index) => (
										<div className="field" key={index}>
											<div className="image-wrap">
												<label>{'Tag ' + (index + 1)}</label>
												<div className="image-icon-wrap" style={{border: 'none'}}>
													<select
														onChange={(e) => this.updateArray(e, 'tags', index)}
														value={tag}>
														{
															this.state.tagOptions?.map(option =>
																<option key={option.key} value={option.key}>{option.label}</option>)
														}
													</select>
													<i className="icon-trash" onClick={() => this.removeTag(index)}></i>
												</div>
											</div>
										</div>
									))} */}
								</div>
							</div>
						</div>

						<div className='section'>
							<span>Project slides</span>

							<Dropzone
								showImage={this.showImage}
							/>

							<div className='wrap-inputs col-4'>
								{this.state.slides.map((slide, index) => (
									// <PreviewImage
									// 	key={index}
									// 	uri={'storage/' + slide.uri.split('-')[0] + '/' + slide.uri}
									// 	label={'Slide ' + (index + 1)}
									// 	name={slide.name}
									// 	removeImage={() => this.removeImage(index)}
									// />
									<div
										key={index}
										className='image-wrap'
									>
										<span className='count'>
											{index + 1 < 10 ? '0' : ''}{index + 1}
										</span>
										<img
											src={API.apiURL + '/storage/' + slide.uri.split('-')[0] + '/' + slide.uri}
											alt=""
										/>
										<span className='name'>
											{slide.name}
											<i onClick={() => this.removeImage(index)} className="icon-trash"></i>
										</span>
									</div>
								))}
							</div>
						</div>
					</div>
					
					{/* {
						this.props.new &&
						<>
							<div className="fields clearfix name">
								<h2>Name</h2>
								<div className="field">
									<input type="text" name="name" value={this.state.name || ''} onChange={(e) => this.updateField(e, e.target.name)}/>
								</div>
							</div>
						</>
					}

					<div className="flex-fields">
						<div className="fields clearfix">
							<h2>Slides</h2>
							{
								this.state.slides.map((slide, index) => (
									<div className="field" key={index}>
										<PreviewImage
											uri={'storage/' + slide.uri.split('-')[0] + '/' + slide.uri}
											label={'Slide ' + (index + 1)}
											name={slide.name}
											removeImage={() => this.removeImage(index)}
										/>
									</div>
								))
							}

							<div className="field">
								<Dropzone
									showImage={this.showImage}
								/>
							</div>
						</div>

						<div className="fields clearfix">
							<h2>Tags</h2>
							{
								this.state.tags?.map((tag, index) => (
									<div className="field" key={index}>
										<div className="image-wrap">
											<label>{'Tag ' + (index + 1)}</label>
											<div className="image-icon-wrap" style={{border: 'none'}}>
												<select
													onChange={(e) => this.updateArray(e, 'tags', index)}
													value={tag}>
													{
														this.state.tagOptions?.map(option =>
															<option key={option.key} value={option.key}>{option.label}</option>)
													}
												</select>
												<i className="icon-trash" onClick={() => this.removeTag(index)}></i>
											</div>
										</div>
									</div>
								))
							}

							<div className="field" onClick={this.newTag}>
								<div className="dropzone image-wrap">
									<div className="image-icon-wrap icon-add">
										<p>Add new tag</p>
									</div>
								</div>
							</div>
						</div>
					</div> */}
				</div>
			</form>
            {
                !this.props.new &&
                <Rodal visible={this.props.popupVisible} onClose={() => this.props.togglePopup()} className={'rodal-container'}>
                    <div className="popup-inner">
                        <div className="header"></div>
                        <h2>Update project name</h2>

                        <div className="field">
                            <label>Project name</label>
                            <input type="text" onChange={(e) => this.setState({...this.state, name: e.target.value})} value={this.state.name}/>
                        </div>
                        <div className="actions">
                            <button className="transparent" onClick={() => this.props.togglePopup()}>Cancel</button>				
                            <button className="blue" onClick={() => this.props.save(this.state)}>Save</button>
                        </div>
                    </div>
                </Rodal>
            }
            </>
		)
	}
}


export default Form;