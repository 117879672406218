import React from 'react'
import API from '../../services/api.jsx'
import Loader from '../layout/loader.jsx'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Table from '../layout/table.jsx'
import moment from 'moment'
import { debounce } from 'lodash'
// import Helpers from '../../services/helpers.jsx'
// import { toast } from 'react-toastify'

class ProjectsList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			clients: [],
			loading: true,
			filters: {
				name: ''
			},
			tabs: {
				links: [
					{ title: 'Manage Projects', path: '/projects' },
					{ title: 'Manage Tags', path: '/tags' }
				]
			},
			projects: {
				columns: [{
					label: 'Name',
					key: 'name',
					order: true,
					className: 'bold',
				}, {
					label: 'Tags',
					key: 'tags',
					view: function(tags) {
						let tag = tags.map(tag => <div key={tag.id} className="tag">{tag.name}</div>)
						return <div className="tags">
							{tag}
						</div>
					}
				}, {
					label: 'Year',
					key: 'createdAt',
					order: true,
					className: 'bold',
					view: function(field) {
						return moment(field).format('YYYY')
					}
				}, {
					label: 'Updated at',
					key: 'updatedAt',
					order: true,
					view: function(field) {
						return moment(field).format('YYYY-MM-DD')
					}
				}, {
					action: 'edit',
					editClicked: (id) => {
						this.props.history.push(`/projects/${id}/edit`)
					}
				}],
				entities: [],
				filters: [
					{
						label: 'Project name',
						onChange: debounce(value => {
							if(value !== this.state.filters.name)
								this.setState({ filters: { ...this.state.filters, name: value ? '/' + value + '/i' : '' }}, () => {
									this.loadEntities()
								})
						}, 500)
					}, {
						label: 'Search for a tag',
						type: 'select',
						value: null,
						entities: [],
						onChange: value => {
							if (value !== this.state.filters.tags) {
								if (value.id === '') {
									const newFilters = this.state.filters
									delete newFilters.tags
									this.setState({ filters: newFilters, projects: { ...this.state.projects, filters: [this.state.projects.filters[0], { ...this.state.projects.filters[1], value: value }] }}, () => {
										this.loadEntities()
									})
								} else {
									this.setState({ filters: { ...this.state.filters, tags: value ? value.id : '' }, projects: { ...this.state.projects, filters: [this.state.projects.filters[0], { ...this.state.projects.filters[1], value: value }] }}, () => {
										this.loadEntities()
									})
								}
							}
						}
					}
				],
				actions: [{
					classList: 'yellow add',
					label: 'New project',
					onClick: () => {
						this.props.history.push(`/projects/new`)
					}
				}],
				selectedItems: [],
				onRowClick: (entity) => {
					this.props.history.push('/projects/' + entity.id + '/edit')
				}
			}
		}
	}

	componentDidMount() {
		// this.loadEntities()

		API.getTags().then(tags => {
			// create index
			tags = [{ name: 'All tags', id: '' }].concat(tags)
			this.setState({ tags: tags, projects: { ...this.state.projects, filters: [this.state.projects.filters[0], { ...this.state.projects.filters[1], entities: tags, value: tags[0] }]} }, () => {
				this.loadEntities()
			})
		})
	}

	loadEntities() {
		API.getProjectsDetails(this.state.filters).then(entities => this.setState({ ...this.state, projects: {...this.state.projects, entities: entities}, loading: false }))
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={<>Manage<br />projects</>} tabs={this.state.tabs} />
				<Content>
					<Table settings={this.state.projects} />
				</Content>
			</>
	}
}

export default ProjectsList;
