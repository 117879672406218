import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from './form.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import API from '../../services/api.jsx'

class TagEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			breadcrumb: {title: 'Back to Tag list', path: '/tags'},
			entity: null
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	componentDidMount() {
		API.getTag(this.props.match.params.tagId).then(tag => this.setState({ loading: false, entity: tag }))
	}

	deleteEntity = () => {
		var r = window.confirm("Are you sure you want to delete this tag?");
		if (r === false) return
		this.setState({ loading: true })
		API.delete('tags', this.props.match.params.tagId).then(() => {
			toast.success('Tag has been deleted')
			this.props.history.push('/tags')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	saveEntity(fields) {
		this.setState({ loading: true })
		const data = {
			name: fields.name
		}

		API.createOrUpdateTag(data, this.props.match.params.tagId).then(() => {
			toast.success('Tag has been updated')
			this.props.history.push('/tags')
		}).catch(() => {
			toast.error('An unexpected error has occured, please try again')
			this.setState({ loading: false })
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head /*title={this.state.entity.name}*/ breadcrumb={this.state.breadcrumb}/>
				<Content>
					<Form cancel={() => this.props.history.push('/tags')} save={this.saveEntity} entity={this.state.entity} popupVisible={this.state.popupVisible} togglePopup={() => this.setState({...this.state, popupVisible: !this.state.popupVisible})}/>
				</Content>
				<div className="actions under-content">
					<button onClick={() => {this.deleteEntity()}} className="red">Delete tag</button>
				</div>
			</>
	}
}

export default TagEdit;
