import React from 'react'
import Head from '../layout/head.jsx'
import Content from '../layout/content.jsx'
import Form from './form.jsx'
import Loader from '../layout/loader.jsx'
import { toast } from 'react-toastify'
import API from '../../services/api.jsx'

class TagNew extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			breadcrumb: {title: 'Back to tags', path: '/tags'},
			entity: {
				name: null
			}
		}
		this.saveEntity = this.saveEntity.bind(this)
	}

	saveEntity(fields) {
		this.setState({ loading: true })
		const data = {
			name: fields.name
		}
		API.createOrUpdateTag(data).then(() => {
			toast.success('Tag has been updated')
			this.props.history.push('/tags')
		}).catch((err) => {
			if (err.response.data.message === 'Tag exists') {
				toast.error('Tag exists, please check again')
				this.setState({ loading: false })
			} else {
				toast.error('An unexpected error has occured, please try again')
				this.setState({ loading: false })
			}
		})
	}

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'New tag'} breadcrumb={this.state.breadcrumb} />
				<Content>
					<Form new cancel={() => this.props.history.push('/tags')} save={this.saveEntity} entity={this.state.entity} />
				</Content>
			</>
	}
}

export default TagNew;
