import React from 'react'
import API from '../../../services/api.jsx'
import Loader from '../../layout/loader.jsx'
import Head from '../../layout/head.jsx'
import Content from '../../layout/content.jsx'
import Table from '../../layout/table.jsx'
import Modal from './modal.jsx'
import moment from 'moment'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'
// import Helpers from '../../services/helpers.jsx'

class PortfolioList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			clients: [],
			loading: true,
			entity: {
				name: null,
				projects: []
			},
			filters: {
				name: ''
			},
			tabs: {
				links: [
					{ title: 'Create', path: '/portfolios/build' },
					{ title: 'Templates', path: '/portfolios/templates' }
				]
			},
			portfolios: {
				columns: [{
					label: 'Name',
					key: 'name',
					order: true,
				}, {
					label: 'Updated at',
					key: 'updatedAt',
					order: true,
					className: 'bold',
					view: function(field) {
						return moment(field).format('YYYY-MM-DD')
					}
				}, {
					label: 'Updated',
					key: 'updatedAt',
					order: true,
					view: function(field) {
						return moment(field).format('YYYY-MM-DD')
					}
				}, {
                    action: 'edit',
                    editClicked: portfolioId => {
                        this.props.history.push(`/portfolios/templates/${portfolioId}/edit`)
                    }
                }],
				entities: [],
				filters: [
					{
						label: 'Template name',
						onChange: debounce(value => {
							if(value !== this.state.filters.name)
								this.setState({ filters: { ...this.state.filters, name: value ? '/' + value + '/i' : '' }}, () => {
									this.loadEntities()
								})
						}, 500)
					}
				],
				actions: [
                    {
                        classList: 'yellow add',
                        label: 'New',
                        onClick: () => {
							this.setState({...this.state, popupVisible: true})
                        }
				    }
                ],
				selectedItems: [],
				onRowClick: (entity) => {
					this.props.history.push('/portfolios/templates/' + entity.id + '/edit')
				}
			}
		}
	}

	componentDidMount() {
		this.loadEntities()
	}

	loadEntities() {
		API.getPortfolios(this.state.filters).then(entities => {this.setState({ ...this.state, portfolios: {...this.state.portfolios, entities}, loading: false })})
	}

	save = () => {
        if(!this.state.entity.name) {
            toast.error('Please enter a name')
        }

        API.create('portfolios', this.state.entity).then(() => {
			this.setState({...this.state, popupVisible: false})
			this.loadEntities()
            toast.success('Portfolio successfully created')
        }).catch(err => {
            toast.success('There was a problem creating this portfolio')
        })
    }   

	render() {
		if(this.state.loading)
			return <Loader />
		else
			return <>
				<Head title={'Templates'} tabs={this.state.tabs}/>
				<Content>
					<Table settings={this.state.portfolios} />
					<Modal 
						create
                        entity={this.state.entity} 
                        popupVisible={this.state.popupVisible} 
                        closePopup={() => this.setState({...this.state, popupVisible: false})} 
                        updateName={(e) => this.setState({...this.state, entity: {...this.state.entity, name: e.target.value}})}
						save={this.save}
                    />
				</Content>
			</>
	}
}

export default PortfolioList;
