import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import Header from './header'
import Login from '../account/login'

import PrivateRoute from './privateRoute'

/* Portfolio */
import PortfolioBuild from '../portfolio/build.jsx'
import PortfolioList from '../portfolio/template/list.jsx'
import PortfolioEdit from '../portfolio/template/edit.jsx'

/* Projects */
import ProjectList from '../projects/list.jsx'
import ProjectNew from '../projects/new.jsx'
import ProjectEdit from '../projects/edit.jsx'

/* Tags */
import TagList from '../tags/list.jsx'
import TagNew from '../tags/new.jsx'
import TagEdit from '../tags/edit.jsx'

function Layout() {
	return (
		<Router>
			<Header />
			<main id="main">
				<ToastContainer position="top-right" />
				<Switch>
					<Route exact path="/login" component={Login} />


					<PrivateRoute exact path="/portfolios/build" component={PortfolioBuild} />
					<PrivateRoute exact path="/portfolios/templates" component={PortfolioList} />
					<PrivateRoute exact path="/portfolios/templates/:portfolioId/edit" component={PortfolioEdit} />

					<PrivateRoute exact path="/projects" component={ProjectList} />
					<PrivateRoute exact path="/projects/new" component={ProjectNew} />
					<PrivateRoute exact path="/projects/:projectId/edit" component={ProjectEdit} />

					<PrivateRoute exact path="/tags" component={TagList} />
					<PrivateRoute exact path="/tags/new" component={TagNew} />
					<PrivateRoute exact path="/tags/:tagId/edit" component={TagEdit} />

					<Route
						exact
						path="/"
						render={() => {
							return (
								<Redirect to="/portfolios/build" />
							)
						}}
					/>
				</Switch>
			</main>
		</Router>
	)
}

export default Layout
