import React from 'react'
import Logo from '../layout/logo'
import User from '../../services/user'
import i18n from '../../services/i18n'
import Loader from '../layout/loader.jsx'
import logoWhite from '../../styles/images/logo-white.png'

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.login = this.login.bind(this)
		this.state = {
			email: '',
			password: '',
			loading: false
		}
	}

	// On component mount, add class on body
	componentDidMount() {
		document.body.classList.add('fullscreen');
	}

	// On component unmount, remove class on body
	componentWillUnmount() {
		document.body.classList.remove('fullscreen');
	}

	// User login
	login(event) {
		this.setState({ loading: true })
		event.preventDefault();
		const { history } = this.props;

		User.login({
			email: this.state.email,
			password: this.state.password
		}).then(() => {
			this.setState({ loading: false })
			history.push('/');
		}).catch((err) => {
			this.setState({ error: true, loading: false })
		});

	}

	render() {
		return (
			<div className='new login'>
				<div className='layer'>
					<div className='top-header'>
						<span>Portfolio<br />generator</span>

						<div>
							<img src={logoWhite} alt="" />
						</div>
					</div>

					<form onSubmit={this.login}>
						<label>{i18n.t('FIELD_EMAIL')}</label>
						<input
							type="email"
							required
							placeholder={i18n.t('FIELD_EMAIL_PLACEHOLDER')} value={this.state.email}
							onChange={(e) => this.setState(
								{ email: e.target.value }
							)}
						/>

						<label>{i18n.t('FIELD_PASSWORD')}</label>
						<input 
						 	type="password"
							required
							placeholder={i18n.t('FIELD_PASSWORD_PLACEHOLDER')} value={this.state.password}
							onChange={(e) => this.setState(
								{ password: e.target.value }
							)}
						/>

						<button disabled={this.state.loading}>
							{this.state.loading ?
								'SENDING...' :
								i18n.t('LOGIN_FORM_SUBMIT')
							}
						</button>
					</form>
				</div>
			</div>
		)

		// Old integration

		// return <div className="login">
		// 	<div className="form-wrapper">
		// 		<Logo />
		// 		<div className="block">
		// 			{
		// 				this.state.loading ? 
		// 					<Loader /> : <>
		// 						<h2>{ i18n.t('LOGIN_FORM_TITLE') }</h2>
		// 						{ this.state.error && <p>Email or password not recognized, please try again</p> }
		// 						<form onSubmit={this.login} className="fields">
		// 							<div className="field">
		// 								<label>{ i18n.t('FIELD_EMAIL') }</label>
		// 								<input type="email" required placeholder={ i18n.t('FIELD_EMAIL_PLACEHOLDER') } value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
		// 							</div>
		// 							<div className="field">
		// 								<label>{ i18n.t('FIELD_PASSWORD') }</label>
		// 								<input type="password" required placeholder={ i18n.t('FIELD_PASSWORD_PLACEHOLDER') } value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
		// 								{/*<Link to="/password">{ i18n.t('LOGIN_FORM_FORGOT_PASSWORD') }</Link>*/}
		// 							</div>
		// 							<button className="black">{ i18n.t('LOGIN_FORM_SUBMIT') }</button>
		// 						</form>
		// 					</>
		// 			}
		// 		</div>
		// 	</div>
		// </div>
	}
}

export default Login
