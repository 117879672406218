import React from 'react'
import Rodal from 'rodal'

class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.state = props.entity
    }

    render() {
        return(
            <Rodal visible={this.props.popupVisible} onClose={() => this.props.closePopup()} className={'rodal-container'}>
                <div className="popup-inner">
                    <div className="header"></div>
                    <h2>{this.props.create ? 'Create Template' : 'Edit Name'}</h2>

                    <div className="field">
                        <label>Portfolio name</label>
                        <input type="text" onChange={(e) => this.props.updateName(e)} value={this.props.entity.name || ''}/>
                    </div>
                    <div className="actions">
                        <button className="transparent" onClick={() => this.props.closePopup()}>Cancel</button>				
                        <button className="blue" onClick={() => this.props.save()}>Save</button>
                    </div>
                </div>
            </Rodal>
        )
    }
}

export default Modal